import React, { useContext } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import Pill from '../../common/buttons/Pill';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';

const PillWrapper = styled.div`
    margin-bottom: 8px;
`;

const CalendarsNavigation = () => {
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const { periodsTab, setPeriodsTab } = useContext(CalendarMenuContext);

    return (
        <PillWrapper>
            <Pill onClick={() => setPeriodsTab('periods')} active={periodsTab === 'periods'}>
                {generalTranslation('periods')}
            </Pill>
            <Pill onClick={() => setPeriodsTab('exclusions')} active={periodsTab === 'exclusions'}>
                {generalTranslation('exceptions')}
            </Pill>
        </PillWrapper>
    );
};

export default CalendarsNavigation;
