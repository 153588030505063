import React, { useContext, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';
import { CalendarsTabContext } from '../../contexts/calendar/CalendarsTabContext';
import Navigation from './Navigation';
import LoadingSpin from '../utils/LoadingSpin';
import CalendarsNavigation from './calendars/CalendarsNavigation';
import CalendarsList from './calendars/CalendarsList';
import CalendarPeriodsList from './calendars/CalendarPeriodsList';
import CalendarExclusionsList from './calendars/CalendarExclusionsList';
import CalendarYearCalendars from './calendars/CalendarYearCalendars';
// import CalendarExclusionsImport from './calendars/CalendarExclusionsImport';
import { CalendarMenuContext } from '../../contexts/app/CalendarMenuContext';
import AuthService from '../../services/auth.service';

const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 40px);
`;

const CalendarsTab = () => {
    const [loading, setLoading] = useState(false);
    const { selectedCalendar, setSelectedCalendar, preserveSelectedCalendar, setPreserveSelectedCalendar } = useContext(CalendarsTabContext);
    const { periodsTab, refreshPeriods, refreshCalendars } = useContext(CalendarMenuContext);
    const { isAdmin } = AuthService.getCurrentUser();

    useEffect(() => {
        setLoading(true);
        Promise.allSettled([refreshPeriods(), refreshCalendars()])
            .then((data) => {
                data.forEach((refreshResponse) => {
                    console.log("🚀 ~ file: CalendarsTab.jsx:36 ~ data.forEach ~ preserveSelectedCalendar:", preserveSelectedCalendar)
                    if (refreshResponse.value && 'calendars' in refreshResponse.value && !preserveSelectedCalendar) {
                        setSelectedCalendar(
                            refreshResponse.value.calendars.filter((calendar) => calendar.defaultCalendar).first() ||
                                refreshResponse.value.calendars.first()
                        );
                    }
                });
            })
            .finally(() => {
                console.log('disabling preserve')
                setPreserveSelectedCalendar(false)
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <Row gutter={[12, 0]} className="relative flex h-full">
            <Col  className="w-1/4 h-full">
                <Navigation />
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <CalendarsList />
                </PanelWrapper>
            </Col>
            <Col className="w-1/4 h-full">
                <CalendarsNavigation />
                {/* {!selectedCalendar || periodsTab === 'periods' ? '' : <CalendarExclusionsImport />} */}
                <PanelWrapper
                    className="max-h-full overflow-y-auto"
                    style={{
                        height:
                            !isAdmin || !selectedCalendar || periodsTab === 'periods'
                                ? 'calc(100% - 40px)'
                                : 'calc(100% - 80px)',
                    }}
                >
                    {!selectedCalendar || periodsTab === 'periods' ? (
                        <CalendarPeriodsList />
                    ) : (
                        <CalendarExclusionsList />
                    )}
                </PanelWrapper>
            </Col>
            <Col className=" w-1/2 max-h-full overflow-y-auto">
                <CalendarYearCalendars />
            </Col>
        </Row>
    );
};

export default CalendarsTab;
