import React, { useContext, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { CalendarsTabContext } from '../../../contexts/calendar/CalendarsTabContext';
import { requestError } from '../../../helpers/notification';

const CalendarAddExclusionForm = ({ calendar, startDate, endDate }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_calendars' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [startAt, setStartAt] = useState(startDate);
    const [endAt, setEndAt] = useState(endDate);
    const { backToMainModal, dayDefinitions, refreshCalendars } = useContext(CalendarMenuContext);
    const { setSelectedCalendar, setPreserveSelectedCalendar } = useContext(CalendarsTabContext);
    const [selectedDayDefinition, setSelectedDayDefinition] = useState([]);

    const addExclusionToCalendar = (values) => {
        setLoading(true);
        CalendarService.addExclusion(
            calendar,
            values.name,
            moment(startAt).format('YYYY-MM-DD'),
            moment(endAt).format('YYYY-MM-DD'),
            selectedDayDefinition
        )
            .then((updatedCalendar) => {
                setPreserveSelectedCalendar(true);
                setSelectedCalendar(updatedCalendar)
                setLoading(false);
                backToMainModal(refreshCalendars);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendar_specific_period_save_error'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        addExclusionToCalendar(values);
    };

    const updateDayDefinition = (value) => {
        setSelectedDayDefinition(dayDefinitions.filter((dayDefinition) => dayDefinition.id === value).first());
    };

    const updateStartAtPickerVal = (value) => {
        setStartAt(value);
    };

    const updateEndAtPickerVal = (value) => {
        setEndAt(value);
    };

    const initValues = {
        startAt: startDate,
        endAt: endDate,
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="calendar_add_exclusion_form"
            onFinish={submitForm}
            initialValues={initValues}
        >
            <Row>
                <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="startAt"
                    label={generalTranslation('start')}
                    className="ml-6"
                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                >
                    <DatePicker onSelect={updateStartAtPickerVal} format={window.dateStringFormat} />
                </Form.Item>
                <Form.Item
                    name="endAt"
                    label={generalTranslation('end')}
                    className="ml-6"
                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                >
                    <DatePicker onSelect={updateEndAtPickerVal} format={window.dateStringFormat} />
                </Form.Item>
                <Form.Item
                    name="day_definition"
                    label={generalTranslation('type')}
                    className="ml-6"
                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                >
                    <Select
                        placeholder={t('select_day_placeholder')}
                        style={{ width: '200px', boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                        onChange={updateDayDefinition}
                        showSearch
                        dropdownClassName="rounded"
                        filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {dayDefinitions.map((dayDefinition) => (
                            <Select.Option
                                key={`calendar-${calendar.id}-day-definition-${dayDefinition.id}-select-option`}
                                value={dayDefinition.id}
                                title={dayDefinition.name}
                            >
                                {dayDefinition.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CalendarAddExclusionForm.propTypes = {
    calendar: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(moment.Moment),
    endDate: PropTypes.instanceOf(moment.Moment),
};

CalendarAddExclusionForm.defaultProps = {
    startDate: null,
    endDate: null,
};

export default CalendarAddExclusionForm;
