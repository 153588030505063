import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { CalendarsTabContext } from '../../../contexts/calendar/CalendarsTabContext';
import { requestError } from '../../../helpers/notification';

const CalendarEditionForm = ({ calendar }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_calendars' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { backToMainModal} = useContext(CalendarMenuContext);
    const { setSelectedCalendar, setPreserveSelectedCalendar } = useContext(CalendarsTabContext);

    const createDayDefinition = (values) => {
        setLoading(true);
        CalendarService.createCalendar({ name: values.name })
            .then((newCalendar) => {
                setPreserveSelectedCalendar(true)
                setSelectedCalendar(newCalendar)
                setLoading(false);
                backToMainModal();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('day_save_error'));
            });
    };

    const updateDayDefinition = (values) => {
        setLoading(true);
        CalendarService.updateCalendarName(calendar, values.name)
            .then((updatedCalendar) => {
                setPreserveSelectedCalendar(true);
                setSelectedCalendar(updatedCalendar)
                setLoading(false);
                backToMainModal();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('day_save_error'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        setLoading(true);
        if (calendar) {
            updateDayDefinition(values);
        } else {
            createDayDefinition(values);
        }
    };

    const entity = {
        name: calendar ? calendar.name : null,
    };

    return (
        <Form form={form} layout="vertical" name="calendar_edit_form" initialValues={entity} onFinish={submitForm}>
            <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                <Input />
            </Form.Item>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CalendarEditionForm.propTypes = {
    calendar: PropTypes.object,
};

CalendarEditionForm.defaultProps = {
    calendar: null,
};

export default CalendarEditionForm;
