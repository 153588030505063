import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Checkbox, Col, Form, Row, Space } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import LoadingSpin from '../../utils/LoadingSpin';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { requestError } from '../../../helpers/notification';

const DayTimeSlotsAssociateForm = ({ timeSlots, selectedDayDefinition }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_days' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [align] = useState('left');
    const { backToMainModal, refreshTimeSlots } = useContext(CalendarMenuContext);
    const [selectedTimeSlotsIds, setSelectedTimeSlotsIds] = useState([]);

    useEffect(() => {
        setSelectedTimeSlotsIds((selectedDayDefinition.timeSlots || []).map((selectedTimeSlot) => selectedTimeSlot.id));
    }, []);

    const associateTimeSlots = () => {
        const originalTimeSlotsIds = (selectedDayDefinition.timeSlots || []).map(
            (selectedTimeSlot) => selectedTimeSlot.id
        );

        setLoading(true);
        new Promise((resolve, reject) => {
            if (originalTimeSlotsIds.length) {
                // Clear old time slots before sync new list
                CalendarService.removeTimeSlot(selectedDayDefinition, originalTimeSlotsIds)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        requestError(error, t('time_slot_disassociate_error'));
                        reject();
                    });
            } else {
                resolve();
            }
        })
            .then(() => {
                CalendarService.addTimeSlots(selectedDayDefinition, selectedTimeSlotsIds)
                    .then(() => {
                        setLoading(false);
                        backToMainModal(refreshTimeSlots);
                    })
                    .catch((error) => {
                        setLoading(false);
                        requestError(error, t('time_slot_associate_error'));
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const submitForm = () => {
        if (loading) {
            return;
        }
        associateTimeSlots();
    };

    const updateCheckboxTimeSlot = (timeSlot) => {
        const newList = [...selectedTimeSlotsIds];
        const index = newList.indexOf(timeSlot.id);
        if (index > -1) {
            newList.splice(index, 1);
        } else {
            newList.push(timeSlot.id);
        }
        setSelectedTimeSlotsIds(newList);
    };

    const formatTime = (time) => moment(time, 'HH:mm.ss.SSSSSSSSS').format('HH:mm');

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <Form form={form} layout="vertical" name="day_time_slots_association_form" onFinish={submitForm}>
            <Space align={align} direction="vertical">
                {timeSlots.map((timeSlot) => (
                    <Checkbox
                        checked={selectedTimeSlotsIds.indexOf(timeSlot.id) >= 0}
                        onClick={() => updateCheckboxTimeSlot(timeSlot)}
                    >
                        {timeSlot.name} ({formatTime(timeSlot.start_time)} à {formatTime(timeSlot.end_time)})
                    </Checkbox>
                ))}
            </Space>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

DayTimeSlotsAssociateForm.propTypes = {
    timeSlots: PropTypes.array,
    selectedDayDefinition: PropTypes.object.isRequired,
};

DayTimeSlotsAssociateForm.defaultProps = {
    timeSlots: [],
};

export default DayTimeSlotsAssociateForm;
