import React, { useContext, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { CalendarsTabContext } from '../../../contexts/calendar/CalendarsTabContext';
import { requestError } from '../../../helpers/notification';

const CalendarAddPeriodForm = ({ periods, calendar, startDate }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_calendars' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [startAt, setStartAt] = useState(startDate);
    const { backToMainModal } = useContext(CalendarMenuContext);
    const { setSelectedCalendar, setPreserveSelectedCalendar } = useContext(CalendarsTabContext);

    const addPeriodToCalendar = () => {
        setLoading(true);
        CalendarService.addPeriod(calendar, selectedPeriod, startAt.format('YYYY-MM-DD'))
            .then((updatedCalendar) => {
                setPreserveSelectedCalendar(true);
                setSelectedCalendar(updatedCalendar)
                setLoading(false);
                backToMainModal();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendar_period_save_error'));
            });
    };

    const submitForm = () => {
        if (loading) {
            return;
        }
        addPeriodToCalendar();
    };

    const updatePeriod = async (value) => {
        setSelectedPeriod(periods.filter((period) => period.id === value).first());
    };

    const updateDatePickerVal = (value) => {
        setStartAt(value);
    };

    const initValues = { startAt: startDate };

    return (
        <Form
            form={form}
            layout="vertical"
            name="calendar_add_period_form"
            onFinish={submitForm}
            initialValues={initValues}
        >
            <Row>
                <Form.Item name="startAt" label={generalTranslation('start')} rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <DatePicker onSelect={updateDatePickerVal} onChange={updateDatePickerVal} format={window.dateStringFormat} />
                </Form.Item>
                <Form.Item
                    name="period"
                    label={generalTranslation('type')}
                    className="ml-6"
                    rules={[{ required: true, message: generalTranslation('required_field') }]}
                >
                    <Select
                        placeholder={t('select_period_placeholder')}
                        style={{ width: '200px', boxShadow: 'inset 2px 3px 3px rgba(130, 130, 130, 0.15)' }}
                        onChange={updatePeriod}
                        showSearch
                        dropdownClassName="rounded"
                        filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {periods.map((period) => (
                            <Select.Option
                                key={`calendar-${calendar.id}-period-${period.id}-select-option`}
                                value={period.id}
                                title={period.name}
                            >
                                {period.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Row>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

CalendarAddPeriodForm.propTypes = {
    periods: PropTypes.array,
    calendar: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(moment.Moment),
};

CalendarAddPeriodForm.defaultProps = {
    periods: [],
    startDate: null,
};

export default CalendarAddPeriodForm;
