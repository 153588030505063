import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, InputNumber, Row } from 'antd';
import styled from 'styled-components';
import LoadingSpin from '../utils/LoadingSpin';
import { PeriodsTabContext } from '../../contexts/calendar/PeriodsTabContext';
import Navigation from './Navigation';
import CalendarService from '../../services/calendar.service';
import PeriodsList from './periods/PeriodsList';
import PeriodDayDefinitionList from './periods/PeriodDayDefinitionList';
import { requestError } from '../../helpers/notification';
import { CalendarMenuContext } from '../../contexts/app/CalendarMenuContext';
import AuthService from '../../services/auth.service';

const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 40px);
`;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #fbbe3f;
`;

const NbDayLabel = styled.label`
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    line-height: 12px;
    color: #828282;
`;

const InputNbDay = styled(InputNumber)`
    height: 24px;
    width: 30px;
    background-color: #f9f9f9;

    input {
        height: 100%;
        padding: 0;
        text-align: center;
    }

    .ant-input-number-handler-wrap {
        display: none;
    }
`;

const PeriodsTab = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'periods_tab' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState();
    const { selectedPeriod, setSelectedPeriod } = useContext(PeriodsTabContext);
    const { refreshPeriods } = useContext(CalendarMenuContext);
    const { isAdmin } = AuthService.getCurrentUser();

    useEffect(() => {
        setLoading(true);
        refreshPeriods()
            .then((data) => {
                setSelectedPeriod(data.periods.first());
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const updatePeriodNbDay = (value) => {
        if (!isAdmin || !value) {
            return;
        }

        setLoading(true);
        CalendarService.updatePeriodNbDay(selectedPeriod, value)
            .then((updatedPeriod) => {
                setLoading(false);
                setSelectedPeriod(updatedPeriod);
                refreshPeriods();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, 'Erreur lors de la mise à jour du nombre de jours');
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <Row gutter={[12, 0]} className="h-full flex">
            <Col  className="w-1/4 h-full">
                <Navigation />
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <PeriodsList />
                </PanelWrapper>
            </Col>

            <Col className="w-3/4 h-full">
                <div className="flex justify-between items-center" style={{ marginBottom: 12 }}>
                    <PanelTitle>{generalTranslation('period')} {selectedPeriod ? selectedPeriod.name : '-'}</PanelTitle>
                    <div>
                        <NbDayLabel className="mr-2">{t('no_days')}</NbDayLabel>
                        <InputNbDay
                            min="1"
                            max="7"
                            value={selectedPeriod ? selectedPeriod.dayDefinitions.length : null}
                            onChange={updatePeriodNbDay}
                            disabled={!isAdmin}
                        />
                    </div>
                </div>
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <PeriodDayDefinitionList />
                </PanelWrapper>
            </Col>
        </Row>
    );
};

export default PeriodsTab;
