import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { requestError } from '../../../helpers/notification';

const TimeSlotDeleteForm = ({ timeSlot }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_days' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { backToMainModal, refreshTimeSlots } = useContext(CalendarMenuContext);

    const submitForm = () => {
        setLoading(true);
        CalendarService.deleteTimeSlot(timeSlot)
            .then(() => {
                setLoading(false);
                backToMainModal(refreshTimeSlots);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('time_slot_delete_error'), generalTranslation('days'));
            });
    };

    return (
        <Form name="time_slot_remove_form" onFinish={submitForm}>
            <p>
                {t('time_slot_delete_warning')} <b>{timeSlot.name}</b> ?
            </p>

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('confirm')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

TimeSlotDeleteForm.propTypes = {
    timeSlot: PropTypes.object.isRequired,
};

export default TimeSlotDeleteForm;
