import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import PeriodEditionForm from './PeriodEditionForm';
import { PeriodsTabContext } from '../../../contexts/calendar/PeriodsTabContext';
import PeriodDeleteForm from './PeriodDeleteForm';
import AuthService from '../../../services/auth.service';

const ListFooters = styled.li`
    margin-top: 4px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 110px;
`;

const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const PeriodsList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_periods' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const { setModalContent, periods } = useContext(CalendarMenuContext);
    const { selectedPeriod, setSelectedPeriod } = useContext(PeriodsTabContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const addPeriod = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'periods-create-period',
            title: t('calendar_create_period'),
            body: <PeriodEditionForm period={null} />,
        });
    };

    const editPeriod = (period) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'periods-edit-period',
            title: t('calendar_edit_period'),
            body: <PeriodEditionForm period={period} />,
        });
    };

    const deletePeriod = (period) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'periods-delete-period',
            title: t('calendar_delete_period'),
            body: <PeriodDeleteForm period={period} />,
        });
    };

    return (
        <ul className="pb-8">
            <li className="p-0 pl-2 mb-1">
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
            </li>
            {periods && periods.length ? (
                periods.map((period) => (
                    <li key={`day-definition-${period.id}`} className="p-0 mb-1">
                        <div className="flex items-stretch">
                            <NameColumn
                                className={`flex-1 flex justify-between items-center rounded-l-sm p-2 text-xs cursor-pointer
                                ${selectedPeriod && selectedPeriod.id === period.id ? 'bg-primary' : 'bg-white'}`}
                                onClick={() => setSelectedPeriod(period)}
                            >
                                {period.name}
                            </NameColumn>
                            {isAdmin && (
                                <ActionsColumn className="flex items-center">
                                    <div
                                        className={`py-2 h-full rounded-r-sm cursor-pointer
                                    ${selectedPeriod && selectedPeriod.id === period.id ? 'bg-primary' : 'bg-white'}
                                   `}
                                        onClick={() => editPeriod(period)}
                                    >
                                        <EditSvg width="16" height="16" className="relative text-secondary-dark" />
                                    </div>
                                    <div
                                        className="py-2 text-center cursor-pointer"
                                        onClick={() => deletePeriod(period)}
                                    >
                                        <CloseSvg width="16" height="16" className="mx-auto" />
                                    </div>
                                </ActionsColumn>
                            )}
                        </div>
                    </li>
                ))
            ) : (
                <Empty description={t('no_period_empty_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    <div className="bg-secondary-light px-2 pb-2">
                        <Button
                            type="secondary"
                            className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                            onClick={addPeriod}
                        >
                            <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                        </Button>
                    </div>
                </ListFooters>
            )}
        </ul>
    );
};

export default PeriodsList;
