import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { DaysTabContext } from '../../contexts/calendar/DaysTabContext';
import Navigation from './Navigation';
import DayDefinitionList from './days/DayDefinitionList';
import DayTimeSlotList from './days/DayTimeSlotList';
import TimeSlotList from './days/TimeSlotList';
import LoadingSpin from '../utils/LoadingSpin';
import { CalendarMenuContext } from '../../contexts/app/CalendarMenuContext';

const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 40px);
`;

const SecondaryPanelWrapper = styled(PanelWrapper)`
    background: #fbbe3f;
    border-radius: 4px;
    padding: 8px;
`;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #fbbe3f;
`;

const PanelSubtitle = styled.p`
    font-size: 10px;
    line-height: 12px;
    color: #bbb8b3;
`;

const DaysTab = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'days_tab' });
    const [loading, setLoading] = useState(false);
    const { selectedDayDefinition, setSelectedDayDefinition } = useContext(DaysTabContext);
    const { refreshTimeSlots, refreshDayDefinitions } = useContext(CalendarMenuContext);

    useEffect(() => {
        setLoading(true);
        Promise.allSettled([refreshTimeSlots(), refreshDayDefinitions()])
            .then((data) => {
                data.forEach((refreshResponse) => {
                    if (refreshResponse.value && 'dayDefinitions' in refreshResponse.value) {
                        setSelectedDayDefinition(refreshResponse.value.dayDefinitions.first());
                    }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <Row gutter={[12, 0]} className="h-full flex">
            <Col className="w-1/4 h-full">
                <Navigation />
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <DayDefinitionList />
                </PanelWrapper>
            </Col>
            <div className='w-3/4 flex'>

            <Col className="w-1/2 h-full">
                <div className="flex justify-between items-center" style={{ marginBottom: 12 }}>
                    <div>
                        <PanelTitle>
                            {t('day_definition_title')} {selectedDayDefinition ? selectedDayDefinition.name : '-'}
                        </PanelTitle>
                        <PanelSubtitle>{t('day_definition_subtitle')}</PanelSubtitle>
                    </div>
                </div>
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <DayTimeSlotList />
                </PanelWrapper>
            </Col>
            <Col className="w-1/2 h-full">
                <div style={{ marginBottom: 24 }}>
                    <PanelTitle>{t('time_slot_title')}</PanelTitle>
                </div>
                <SecondaryPanelWrapper className="max-h-full overflow-y-auto">
                    <TimeSlotList />
                </SecondaryPanelWrapper>
            </Col>
            </div>
        </Row>
    );
};

export default DaysTab;
