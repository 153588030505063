import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Empty, Popconfirm } from 'antd';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { CalendarsTabContext, CalendarsTabContextProvider } from '../../../contexts/calendar/CalendarsTabContext';
import CalendarAddPeriodForm from './CalendarAddPeriodForm';
import LoadingSpin from '../../utils/LoadingSpin';
import { requestError } from '../../../helpers/notification';
import AuthService from '../../../services/auth.service';

const ListRow = styled.li`
    padding: 0;
    margin-bottom: 2px;
`;

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const ListFooters = styled.li`
    margin-top: 2px;
`;

const ListDetails = styled.span`
    display: inline-block;
    padding: 8px;
    font-size: 12px;
    width: 90%;
`;

const ListActions = styled.span`
    display: inline-block;
    padding: 0 3px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    display: inline-block;
    width: 60%;
`;

const StartColumn = styled.span`
    display: inline-block;
    width: 40%;

`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const StartColumnHeader = styled(StartColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const CalendarPeriodsList = () => {
    const { t } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { selectedCalendar, setSelectedCalendar } = useContext(CalendarsTabContext);
    const { setModalContent, periods, refreshCalendars } = useContext(CalendarMenuContext);
    const [calendarPeriods, setCalendarPeriods] = useState({});
    const { isAdmin } = AuthService.getCurrentUser();

    const addPeriod = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'calendar-add-period',
            title: t('calendars_calendars.calendar_add_period_calendar'),
            body: <CalendarsTabContextProvider><CalendarAddPeriodForm periods={periods} calendar={selectedCalendar} /></CalendarsTabContextProvider>,
        });
    };

    useEffect(() => {
        setCalendarPeriods(selectedCalendar ? selectedCalendar.periods ?? {} : {});
    }, [selectedCalendar]);

    const removePeriod = (periodStart) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.removePeriod(selectedCalendar, periodStart)
            .then((updatedCalendar) => {
                setLoading(false);
                setSelectedCalendar(updatedCalendar);
                refreshCalendars();
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendars_periods.period_delete_error'));
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <ul className="pb-8">
            <ListHeaders>
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
                <StartColumnHeader>{generalTranslation('start')}</StartColumnHeader>
            </ListHeaders>
            {Object.keys(calendarPeriods).length ? (
                Object.keys(calendarPeriods).map((periodStart) => (
                    <ListRow key={`calendar-${selectedCalendar.id}-period-${periodStart}`}>
                        <ListDetails className="bg-white">
                            <NameColumn>{calendarPeriods[periodStart].name}</NameColumn>
                            <StartColumn>{moment(periodStart).format('DD/MM/YYYY')}</StartColumn>
                        </ListDetails>
                        {isAdmin && (
                            <ListActions>
                                <Popconfirm
                                    title={t('calendars_calendars.calendar_remove_period_warning')}
                                    okText={generalTranslation('yes')}
                                    cancelText={generalTranslation('no')}
                                    onConfirm={() => removePeriod(periodStart)}
                                >
                                    <CloseSvg
                                        width="16"
                                        height="16"
                                        style={{ position: 'relative', top: 3 }}
                                        className="cursor-pointer"
                                    />
                                </Popconfirm>
                            </ListActions>
                        )}
                    </ListRow>
                ))
            ) : (
                <Empty description={t('calendars_periods.no_period_empty_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    {selectedCalendar && (
                        <div className="bg-secondary-light px-2 pb-2">
                            <Button
                                type="secondary"
                                className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                                onClick={addPeriod}
                            >
                                <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                            </Button>
                            <span className='text-xs'>{t('calendars_calendars.add_by_calendar_tips')}</span> 
                        </div>
                    )}
                </ListFooters>
            )}
        </ul>
    );
};

export default CalendarPeriodsList;
