import React, { useContext } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import { Button, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import TimeSlotEditionForm from './TimeSlotEditionForm';
import TimeSlotDeleteForm from './TimeSlotDeleteForm';
import AuthService from '../../../services/auth.service';

const ListRow = styled.li`
    padding: 0;
    margin-bottom: 2px;
`;

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const ListFooters = styled.li`
    padding-left: 0.35rem;
    padding-right: 0.35rem;
    margin-top: 4px;
`;

const ListDetails = styled.span`
    display: inline-block;
    background: white;
    border-radius: 2px;
    padding: 8px;
    font-size: 12px;
`;

const ListActions = styled.span`
    padding: 0 6px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    display: inline-block;
    width: 190px;
`;

const StartColumn = styled.span`
    display: inline-block;
    width: 45px;
`;

const EndColumn = styled.span`
    display: inline-block;
    width: 45px;
`;

const ActionsColumn = styled.span`
    display: inline-block;
    width: 16px;
`;

const NameColumnHeader = styled(NameColumn)`
    font-size: 12px;
    margin-bottom: 10px;
`;

const StartColumnHeader = styled(StartColumn)`
    font-size: 12px;
    margin-bottom: 10px;
`;

const EndColumnHeader = styled(EndColumn)`
    font-size: 12px;
    margin-bottom: 10px;
`;

const TimeSlotList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_days' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const { setModalContent, timeSlots } = useContext(CalendarMenuContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const addTimeSlot = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-create-time-slot',
            title: t('calendars_create_time_slot'),
            body: <TimeSlotEditionForm timeSlot={null} />,
        });
    };

    const editTimeSlot = (timeSlot) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-edit-time-slot',
            title: t('calendars_edit_time_slot'),
            body: <TimeSlotEditionForm timeSlot={timeSlot} />,
        });
    };

    const deleteTimeSlot = (timeSlot) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-delete-time-slot',
            title: t('calendars_delete_time_slot'),
            body: <TimeSlotDeleteForm timeSlot={timeSlot} />,
        });
    };

    const formatTime = (time) => moment(time, 'HH:mm.ss.SSSSSSSSS').format('HH:mm');

    return (
        <ul className="pb-8">
            <ListHeaders>
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
                <StartColumnHeader>{generalTranslation('start')}</StartColumnHeader>
                <EndColumnHeader>{generalTranslation('end')}</EndColumnHeader>
            </ListHeaders>
            {timeSlots && timeSlots.length ? (
                timeSlots.map((timeSlot) => (
                    <ListRow key={`time-slot-${timeSlot.id}`}>
                        <div className="flex items-stretch">
                            <ListDetails>
                                <NameColumn>{timeSlot.name}</NameColumn>
                                <StartColumn>{formatTime(timeSlot.start_time)}</StartColumn>
                                <EndColumn>{formatTime(timeSlot.end_time)}</EndColumn>
                                {isAdmin && (
                                    <ActionsColumn>
                                        <EditSvg
                                            width="16"
                                            height="16"
                                            className="relative text-secondary-dark cursor-pointer"
                                            onClick={() => editTimeSlot(timeSlot)}
                                        />
                                    </ActionsColumn>
                                )}
                            </ListDetails>
                            {isAdmin && (
                                <ListActions className="flex items-center">
                                    <CloseSvg
                                        width="16"
                                        height="16"
                                        className="relative text-secondary-dark cursor-pointer"
                                        onClick={() => deleteTimeSlot(timeSlot)}
                                    />
                                </ListActions>
                            )}
                        </div>
                    </ListRow>
                ))
            ) : (
                <Empty description={t('no_time_slot_empty_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full">
                    <div className="bg-primary px-2 pb-2">
                        <Button
                            type="secondary"
                            className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                            onClick={addTimeSlot}
                        >
                            <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                        </Button>
                    </div>
                </ListFooters>
            )}
        </ul>
    );
};

export default TimeSlotList;
