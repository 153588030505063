import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Button, Empty, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import CalendarEditionForm from './CalendarEditionForm';
import CalendarDeleteForm from './CalendarDeleteForm';
import { CalendarsTabContext, CalendarsTabContextProvider } from '../../../contexts/calendar/CalendarsTabContext';
import CalendarService from '../../../services/calendar.service';
import LoadingSpin from '../../utils/LoadingSpin';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { requestError } from '../../../helpers/notification';
import AuthService from '../../../services/auth.service';

const ListFooters = styled.li`
    margin-top: 4px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 110px;
`;

const DefaultColumn = styled.span`
    width: 22px;

    div {
        width: 22px;
    }
`;
const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const CalendarsList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_calendars' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { setModalContent, calendars, refreshCalendars } = useContext(CalendarMenuContext);
    const { selectedCalendar, setSelectedCalendar } = useContext(CalendarsTabContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const addCalendar = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'calendars-create-calendar',
            title: t('calendar_create_calendar'),
            body: <CalendarsTabContextProvider><CalendarEditionForm calendar={null} /></CalendarsTabContextProvider>,
        });
    };

    const editCalendar = (calendar) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'calendars-edit-calendar',
            title: t('calendar_edit_calendar'),
            body: <CalendarsTabContextProvider><CalendarEditionForm calendar={calendar} /></CalendarsTabContextProvider>,
        });
    };

    const deleteCalendar = (calendar) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'calendars-delete-calendar',
            title: t('calendar_delete_calendar'),
            body: <CalendarDeleteForm calendar={calendar} />,
        });
    };

    const updateDefaultCalendar = (calendar) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.updateDefaultCalendar(calendar)
            .then((defaultCalendar) => {
                setSelectedCalendar(defaultCalendar);
                refreshCalendars().finally(() => {
                    setLoading(false);
                });
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendar_default_error_retrieving'));
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <ul className="pb-8">
            <li className="p-0 pl-2 mb-1">
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
            </li>
            {calendars.length ? (
                calendars.map((calendar) => (
                    <li key={`calendar-${calendar.id}`} className="p-0 mb-1">
                        <div className="flex items-stretch">
                            {isAdmin && (
                                <DefaultColumn className="flex items-center">
                                    <Radio
                                        name="default_calendar"
                                        checked={calendar.defaultCalendar}
                                        onChange={() => updateDefaultCalendar(calendar)}
                                    />
                                </DefaultColumn>
                            )}
                            <NameColumn
                                className={`flex-1 flex justify-between items-center rounded-l-sm p-2 text-xs cursor-pointer
                                ${selectedCalendar && selectedCalendar.id === calendar.id ? 'bg-primary' : 'bg-white'}`}
                                onClick={() => setSelectedCalendar(calendar)}
                            >
                                {calendar.name}
                            </NameColumn>
                            {isAdmin && (
                                <ActionsColumn className="flex items-center">
                                    <div
                                        className={`py-2 h-full rounded-r-sm cursor-pointer
                                    ${
                                        selectedCalendar && selectedCalendar.id === calendar.id
                                            ? 'bg-primary'
                                            : 'bg-white'
                                    }
                                    `}
                                        onClick={() => editCalendar(calendar)}
                                    >
                                        <EditSvg width="16" height="16" className="relative text-secondary-dark" />
                                    </div>
                                    <div
                                        className="py-2 text-center cursor-pointer"
                                        onClick={() => deleteCalendar(calendar)}
                                    >
                                        <CloseSvg width="16" height="16" className="mx-auto" />
                                    </div>
                                </ActionsColumn>
                            )}
                        </div>
                    </li>
                ))
            ) : (
                <Empty description={t('no_calendar_empty_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    <div className="bg-secondary-light px-2 pb-2">
                        <Button
                            type="secondary"
                            className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                            onClick={addCalendar}
                        >
                            <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                        </Button>
                    </div>
                </ListFooters>
            )}
        </ul>
    );
};

export default CalendarsList;
