import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row, Space, TimePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { requestError } from '../../../helpers/notification';

const TimeSlotEditionForm = ({ timeSlot }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_days' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [align] = useState('center');
    const { backToMainModal, refreshTimeSlots } = useContext(CalendarMenuContext);

    const createTimeSlot = (values) => {
        setLoading(true);
        CalendarService.createTimeSlot({
            name: values.name,
            start_time: moment(values.start_time, 'HH:mm').format('HH:mm.ss.SSSSSSSSS'),
            end_time: moment(values.end_time, 'HH:mm').format('HH:mm.ss.SSSSSSSSS'),
        })
            .then(() => {
                setLoading(false);
                backToMainModal(refreshTimeSlots);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('time_slot_create_error'));
            });
    };

    const updateTimeSlot = (values) => {
        setLoading(true);
        CalendarService.updateTimeSlotName(timeSlot, values.name)
            .then(() => {
                setLoading(false);
                backToMainModal(refreshTimeSlots);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('time_slot_update_error'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        if (timeSlot) {
            updateTimeSlot(values);
        } else {
            createTimeSlot(values);
        }
    };

    const entity = {
        name: timeSlot ? timeSlot.name : null,
        start_time: timeSlot ? moment(timeSlot.start_time, 'HH:mm.ss.SSSSSSSSS') : null,
        end_time: timeSlot ? moment(timeSlot.end_time, 'HH:mm.ss.SSSSSSSSS') : null,
    };

    const format = 'HH:mm';

    const updateTimePickerVal = (attr, value) => {
        form.setFieldsValue({ [attr]: value });
    };

    return (
        <Form form={form} layout="vertical" name="time_slot_edit_form" initialValues={entity} onFinish={submitForm}>
            <Space align={align}>
                <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={generalTranslation('start')} name="start_time" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <TimePicker
                        format={format}
                        onSelect={(value) => updateTimePickerVal('start_time', value)}
                        disabled={timeSlot !== null}
                    />
                </Form.Item>
                <Form.Item label={generalTranslation('end')} name="end_time" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                    <TimePicker
                        format={format}
                        onSelect={(value) => updateTimePickerVal('end_time', value)}
                        disabled={timeSlot !== null}
                    />
                </Form.Item>
            </Space>
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

TimeSlotEditionForm.propTypes = {
    timeSlot: PropTypes.object,
};

TimeSlotEditionForm.defaultProps = {
    timeSlot: null,
};

export default TimeSlotEditionForm;
