import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import { notificationError, requestError } from '../../../helpers/notification';

const PeriodEditionForm = ({ period }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_periods' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [align] = useState('center');
    const { backToMainModal, dayDefinitions, refreshPeriods } = useContext(CalendarMenuContext);
    const [selectedDayDefinitionsIds, setSelectedDayDefinitionsIds] = useState([]);

    const createPeriod = (values) => {
        if (selectedDayDefinitionsIds.length <= 7) {
            setLoading(true);
            CalendarService.createPeriod({ name: values.name, dayDefinitionsIds: selectedDayDefinitionsIds })
                .then(() => {
                    setLoading(false);
                    backToMainModal(refreshPeriods);
                })
                .catch((error) => {
                    setLoading(false);
                    requestError(error, t('period_create_error'));
                });
        } else {
            notificationError(
                t('select_day_max_warning'),
                t('period_create_error')
            );
        }
    };

    const updatePeriod = (values) => {
        setLoading(true);
        CalendarService.updatePeriodName(period, values.name)
            .then(() => {
                setLoading(false);
                backToMainModal(refreshPeriods);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('period_update_error'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        if (period) {
            updatePeriod(values);
        } else {
            createPeriod(values);
        }
    };

    const updateCheckboxDayDefinition = (timeSlot) => {
        const newList = [...selectedDayDefinitionsIds];
        const index = newList.indexOf(timeSlot.id);
        if (index > -1) {
            newList.splice(index, 1);
        } else {
            newList.push(timeSlot.id);
        }
        setSelectedDayDefinitionsIds(newList);
    };

    const entity = {
        name: period ? period.name : null,
    };

    return (
        <Form form={form} layout="vertical" name="period_edit_form" initialValues={entity} onFinish={submitForm}>
            <Form.Item label={generalTranslation('name')} name="name" rules={[{ required: true, message: generalTranslation('required_field') }]}>
                <Input />
            </Form.Item>
            {!period && (
                <div className="flex flex-col items-center justify-around">
                    <Space align={align} direction="vertical" className="mt-3">
                        {dayDefinitions.map((dayDefinition) => (
                            <Checkbox
                                key={`checkbox-${dayDefinition.id}`}
                                checked={selectedDayDefinitionsIds.indexOf(dayDefinition.id) >= 0}
                                onClick={() => updateCheckboxDayDefinition(dayDefinition)}
                            >
                                {dayDefinition.name}
                            </Checkbox>
                        ))}
                    </Space>
                </div>
            )}
            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={backToMainModal}>
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

PeriodEditionForm.propTypes = {
    period: PropTypes.object,
};

PeriodEditionForm.defaultProps = {
    period: null,
};

export default PeriodEditionForm;
