import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Empty, Popconfirm, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import CalendarService from '../../../services/calendar.service';
import { PeriodsTabContext } from '../../../contexts/calendar/PeriodsTabContext';
import ColorPreview from '../../utils/ColorPreview';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import LoadingSpin from '../../utils/LoadingSpin';
import { notificationError, requestError } from '../../../helpers/notification';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import AuthService from '../../../services/auth.service';

const ListRow = styled.li`
    padding: 0;
    margin-bottom: 2px;
`;

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const ListDetails = styled.span`
    border-radius: 2px;
    padding: 8px;
    font-size: 12px;
`;

const ListActions = styled.span`
    display: inline-block;
    padding: 0 6px;
`;

const NameColumn = styled.span`
    display: inline-block;
    width: 498px;
`;

const SelectDayColumn = styled.span`
    display: inline-block;
    width: 240px;

    .day-select {
        width: 240px;
        box-shadow: inset 2px 3px 3px rgba(130, 130, 130, 0.15);
    }
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const SelectDayColumnHeader = styled(SelectDayColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const PeriodDayDefinitionList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'calendars_periods' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { dayDefinitions, refreshPeriods } = useContext(CalendarMenuContext);
    const { selectedPeriod, setSelectedPeriod } = useContext(PeriodsTabContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const updateDayDefinition = (value, index) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.updatePeriodDayDefinition(selectedPeriod, index, value)
            .then((updatedPeriod) => {
                setSelectedPeriod(updatedPeriod);
                refreshPeriods().finally(() => {
                    setLoading(false);
                });
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('day_charging_error'));
            });
    };

    const removeDayDefinition = (index) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.removeDayDefinition(selectedPeriod, index)
            .then((updatedPeriod) => {
                setSelectedPeriod(updatedPeriod);
                refreshPeriods().finally(() => {
                    setLoading(false);
                });
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);

                if (error?.response?.data?.type === 'required_field_error') {
                    notificationError(
                        t('day_delete_error'),
                        t('period_contain_message_info')
                    );
                } else {
                    requestError(error, t('day_delete_error'));
                }
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    const days = [];
    if (selectedPeriod) {
        for (let index = 0; index < selectedPeriod.dayDefinitions.length; index += 1) {
            days.push(
                <ListRow key={`period-${selectedPeriod.id}-day-definition-${index}`}>
                    <ListDetails className="inline-block bg-white">
                        <NameColumn>{generalTranslation('day')} {index + 1}</NameColumn>
                        <SelectDayColumn>
                            {isAdmin ? (
                                <Select
                                    placeholder={t('choose_time')}
                                    onChange={(e) => updateDayDefinition(e, index)}
                                    value={selectedPeriod.dayDefinitions[index].id}
                                    showSearch
                                    dropdownClassName="rounded"
                                    filterOption={(input, option) =>
                                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    className="day-select"
                                >
                                    {dayDefinitions.map((dayDefinition) => (
                                        <Select.Option
                                            key={`period-${selectedPeriod.id}-day-definition-${dayDefinition.id}-select-option-${index}`}
                                            value={dayDefinition.id}
                                            title={dayDefinition.name}
                                        >
                                            <div className="flex items-center">
                                                <span className="mr-2">
                                                    <ColorPreview color={dayDefinition.color} display="block" />
                                                </span>
                                                {dayDefinition.name}
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : (
                                <>
                                    <span className="inline-block mr-2 relative" style={{ top: 4 }}>
                                        <ColorPreview color={selectedPeriod.dayDefinitions[index].color} />
                                    </span>
                                    {selectedPeriod.dayDefinitions[index].name}
                                </>
                            )}
                        </SelectDayColumn>
                    </ListDetails>
                    {isAdmin && (
                        <ListActions>
                            <Popconfirm
                                title={t('day_delete_warning')}
                                okText={generalTranslation('yes')}
                                cancelText={generalTranslation('no')}
                                onConfirm={() => removeDayDefinition(index)}
                            >
                                <CloseSvg width="16" height="16" className="cursor-pointer mx-auto" />
                            </Popconfirm>
                        </ListActions>
                    )}
                </ListRow>
            );
        }
    }

    return (
        <ul>
            <ListHeaders>
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
                <SelectDayColumnHeader>{generalTranslation('day')}</SelectDayColumnHeader>
            </ListHeaders>
            {selectedPeriod ? (
                days
            ) : (
                <Empty description={t('no_period_selected_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </ul>
    );
};

export default PeriodDayDefinitionList;
