import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import { Button, Empty, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import CalendarService from '../../../services/calendar.service';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import DayTimeSlotsAssociateForm from './DayTimeSlotsAssociateForm';
import LoadingSpin from '../../utils/LoadingSpin';
import { DaysTabContext } from '../../../contexts/calendar/DaysTabContext';
import { requestError } from '../../../helpers/notification';
import AuthService from '../../../services/auth.service';

const ListRow = styled.li`
    padding: 0;
    margin-bottom: 2px;
`;

const ListHeaders = styled.li`
    padding: 0 0 0 8px;
    margin-bottom: 2px;
`;

const ListFooters = styled.li`
    margin-top: 2px;
`;

const ListDetails = styled.span`
    display: inline-block;
    padding: 8px;
    font-size: 12px;
    width: 90%;
`;

const ListActions = styled.span`
    display: inline-block;
    padding: 0 6px;
`;

const NameColumn = styled.span`
    display: inline-block;
    width: calc(100% - 90px);
`;

const StartColumn = styled.span`
    display: inline-block;
    width: 45px;
`;

const EndColumn = styled.span`
    display: inline-block;
    width: 45px;
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const StartColumnHeader = styled(StartColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const EndColumnHeader = styled(EndColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const DayTimeSlotList = () => {
    const { t, i18n } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [dayDefinitionTimeSlotsList, setDayDefinitionTimeSlotsList] = useState([]);
    const { selectedDayDefinition, setSelectedDayDefinition } = useContext(DaysTabContext);
    const { setModalContent, timeSlots, refreshDayDefinitions } = useContext(CalendarMenuContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const associateTimeSlots = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-associate-time-slots',
            title: i18n.t('calendars_days.calendars_modification_time_slot'),
            body: <DayTimeSlotsAssociateForm timeSlots={timeSlots} selectedDayDefinition={selectedDayDefinition} />,
        });
    };

    useEffect(() => {
        setDayDefinitionTimeSlotsList(selectedDayDefinition ? selectedDayDefinition.timeSlots : []);
    }, [selectedDayDefinition]);

    const removeTimeSlot = async (timeSlot) => {
        if (!isAdmin) {
            return;
        }

        CalendarService.removeTimeSlot(selectedDayDefinition, [timeSlot.id])
            .then((dayDefinition) => {
                setSelectedDayDefinition(dayDefinition);
                refreshDayDefinitions().finally(() => setLoading(false));
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendars_days.time_slot_day_delete_error'));
            });
    };

    const formatTime = (time) => moment(time, 'HH:mm.ss.SSSSSSSSS').format('HH:mm');

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <ul className="pb-8">
            <ListHeaders>
                <ListDetails>
                    <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
                    <StartColumnHeader>{generalTranslation('start')}</StartColumnHeader>
                    <EndColumnHeader>{generalTranslation('end')}</EndColumnHeader>
                </ListDetails>
            </ListHeaders>
            {dayDefinitionTimeSlotsList && dayDefinitionTimeSlotsList.length ? (
                dayDefinitionTimeSlotsList.map((timeSlot) => (
                    <ListRow key={`day-time-slot-${timeSlot.id}`}>
                        <ListDetails className="bg-white">
                            <NameColumn>{timeSlot.name}</NameColumn>
                            <StartColumn>{formatTime(timeSlot.start_time)}</StartColumn>
                            <EndColumn>{formatTime(timeSlot.end_time)}</EndColumn>
                        </ListDetails>
                        {isAdmin && (
                            <ListActions>
                                <Popconfirm
                                    title={t('calendars_days.time_slot_day_delete_warning')}
                                    okText={generalTranslation('yes')}
                                    cancelText={generalTranslation('no')}
                                    onConfirm={() => removeTimeSlot(timeSlot)}
                                >
                                    <CloseSvg
                                        width="16"
                                        height="16"
                                        style={{ position: 'relative', top: 3 }}
                                        className="cursor-pointer"
                                    />
                                </Popconfirm>
                            </ListActions>
                        )}
                    </ListRow>
                ))
            ) : (
                <Empty
                    description={t('calendars_days.time_slot_empty_placeholder')}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    {selectedDayDefinition && (
                        <div className="bg-secondary-light px-2 pb-2">
                            <Button
                                type="secondary"
                                className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                                onClick={associateTimeSlots}
                            >
                                {t('calendars_days.associate_time_slot')}
                            </Button>
                        </div>
                    )}
                </ListFooters>
            )}
        </ul>
    );
};

export default DayTimeSlotList;
