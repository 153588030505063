import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Pill from '../common/buttons/Pill';
import { CalendarMenuContext } from '../../contexts/app/CalendarMenuContext';

const PillWrapper = styled.div`
    margin-bottom: 8px;
`;

const Navigation = () => {
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const { activeTab, setActiveTab } = useContext(CalendarMenuContext);

    return (
        <PillWrapper>
            <Pill onClick={() => setActiveTab('days')} active={activeTab === 'days'}>
                {generalTranslation('days')}
            </Pill>
            <Pill onClick={() => setActiveTab('periods')} active={activeTab === 'periods'}>
                {generalTranslation('periods')}
            </Pill>
            <Pill onClick={() => setActiveTab('calendars')} active={activeTab === 'calendars'}>
                {generalTranslation('calendars')}
            </Pill>
        </PillWrapper>
    );
};

export default Navigation;
