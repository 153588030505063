import React, {useContext, useState} from 'react';
import styled from 'styled-components';

import {Button, Empty, Radio} from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/edit.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import ColorPreview from '../../utils/ColorPreview';
import { CalendarMenuContext } from '../../../contexts/app/CalendarMenuContext';
import DayDefinitionEditionForm from './DayDefinitionEditionForm';
import DayDefinitionDeleteForm from './DayDefinitionDeleteForm';
import { DaysTabContext } from '../../../contexts/calendar/DaysTabContext';
import AuthService from '../../../services/auth.service';
import CalendarService from "../../../services/calendar.service";
import {requestError} from "../../../helpers/notification";
import LoadingSpin from "../../utils/LoadingSpin";

const ListFooters = styled.li`
    margin-top: 4px;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 110px;
`;

const DefaultColumn = styled.span`
    width: 22px;

    div {
        width: 22px;
    }
`;

const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const NameColumnHeader = styled(NameColumn)`
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
`;

const DayDefinitionList = () => {
    const { t, i18n } = useTranslation();
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const { setModalContent, dayDefinitions, refreshDayDefinitions } = useContext(CalendarMenuContext);
    const { selectedDayDefinition, setSelectedDayDefinition } = useContext(DaysTabContext);
    const { isAdmin } = AuthService.getCurrentUser();

    const addDayDefinition = () => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-create-day-definition',
            title: i18n.t('calendars_days.calendars_create_day'),
            body: <DayDefinitionEditionForm dayDefinition={null} />,
        });
    };

    const editDayDefinition = (dayDefinition) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-edit-day-definition',
            title: i18n.t('calendars_days.calendars_edit_day'),
            body: <DayDefinitionEditionForm dayDefinition={dayDefinition} />,
        });
    };

    const deleteDayDefinition = (dayDefinition) => {
        if (!isAdmin) {
            return;
        }

        setModalContent({
            key: 'days-delete-day-definition',
            title: i18n.t('calendars_days.calendars_delete_day'),
            body: <DayDefinitionDeleteForm dayDefinition={dayDefinition} />,
        });
    };

    const updateDefaultDayDefinition = (dayDefinition) => {
        if (!isAdmin) {
            return;
        }

        setLoading(true);
        CalendarService.updateDefaultDayDefinition(dayDefinition)
            .then((defaultDayDefinition) => {
                setSelectedDayDefinition(defaultDayDefinition);
                refreshDayDefinitions().finally(() => {
                    setLoading(false);
                });
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('calendars_days.default_calendar_error_retrieving'));
            });
    };

    if (loading) {
        return <LoadingSpin />;
    }

    return (
        <ul className="pb-8">
            <li className="p-0 pl-2 mb-1">
                <NameColumnHeader>{generalTranslation('name')}</NameColumnHeader>
            </li>
            {dayDefinitions && dayDefinitions.length ? (
                dayDefinitions.map((dayDefinition) => (
                    <li key={`day-definition-${dayDefinition.id}`} className="p-0 mb-1">
                        <div className="flex items-stretch">
                            {isAdmin && (
                                <DefaultColumn className="flex items-center">
                                    <Radio
                                        name="default_calendar"
                                        checked={dayDefinition.defaultDayDefinition}
                                        onChange={() => updateDefaultDayDefinition(dayDefinition)}
                                    />
                                </DefaultColumn>
                            )}
                            <NameColumn
                                className={`flex-1 flex justify-between items-center rounded-l-sm p-2 text-xs cursor-pointer
                                ${
                                    selectedDayDefinition && selectedDayDefinition.id === dayDefinition.id
                                        ? 'bg-primary'
                                        : 'bg-white'
                                }`}
                                onClick={() => setSelectedDayDefinition(dayDefinition)}
                            >
                                {dayDefinition.name}
                                <ColorPreview color={dayDefinition.color} />
                            </NameColumn>
                            {isAdmin && (
                                <ActionsColumn className="flex items-center">
                                    <div
                                        className={`py-2 h-full rounded-r-sm cursor-pointer
                                    ${
                                        selectedDayDefinition && selectedDayDefinition.id === dayDefinition.id
                                            ? 'bg-primary'
                                            : ' bg-white'
                                    }
                                    `}
                                        onClick={() => editDayDefinition(dayDefinition)}
                                    >
                                        <EditSvg width="16" height="16" className="relative text-secondary-dark" />
                                    </div>
                                    <div
                                        className="py-2 text-center cursor-pointer"
                                        onClick={() => deleteDayDefinition(dayDefinition)}
                                    >
                                        <CloseSvg width="16" height="16" className="mx-auto" />
                                    </div>
                                </ActionsColumn>
                            )}
                        </div>
                    </li>
                ))
            ) : (
                <Empty description={t('calendars_days.no_day_empty_placeholder')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {isAdmin && (
                <ListFooters className="absolute bottom-0 left-0 right-0 w-full px-3">
                    <div className="bg-secondary-light px-2 pb-2">
                        <Button
                            type="secondary"
                            className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                            onClick={addDayDefinition}
                        >
                            <PlusIcon className="absolute left-0 ml-3" /> {generalTranslation('add')}
                        </Button>
                    </div>
                </ListFooters>
            )}
        </ul>
    );
};

export default DayDefinitionList;
